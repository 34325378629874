<template>
  <div class="pageborder">
    <div class="pageback">
      <envir-page-name style="background-color: #ffffff;" :noBack="true" pageName="排名查询" />
      <div class="pagepadding">
        <el-button
          size="small"
          type="success"
          style="margin-left: 16px;margin-bottom: 16px;"
          @click="pushRank()">发布排名</el-button>
        <span style="margin-left: 20px;margin-right: 20px;">时间间隔</span>
        <el-date-picker
          v-model="datevalue"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getList()"
          :picker-options="Global.pickerOptions">
        </el-date-picker>
        <div v-loading="loading">
          <el-table
            :data="tableData"
            :row-key="row => row.id"
            style="width: 100%">
            <el-table-column label="顺位" prop="ranknum"> </el-table-column>
            <el-table-column label="唯一ID" prop="playerid"> </el-table-column>
            <el-table-column label="头像" prop="avatarurl">
              <template slot-scope="scope">
                <img :src="scope.row.avatarurl" width="48" height="48" style="margin-bottom: -8px">
              </template>
            </el-table-column>
            <el-table-column label="名称" prop="nickname"> </el-table-column>
            <el-table-column label="得分" prop="score"> </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 30px">
          <el-pagination
            background
            layout="total"
            :total="allamount"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api';

export default {
  data() {
    return {
      params: {
        page: 1,
        pagesize: 10
      },
      tableData: [],
      allamount: 0,
      loading: false,
      datevalue: (() => {
        var now = new Date(); // 当前日期
        var nowYear = now.getFullYear(); //当前年
        var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
        var firstDay = new Date(nowYear, nowMonth, 1); // 本月开始时间
        var LastDay = new Date(nowYear, nowMonth + 1, 1); // 本月结束时间
        return [firstDay, LastDay];
      })()
    };
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList(){
      try{
        this.loading = true;
        var postbody = {}
        if(this.datevalue && this.datevalue[0] && this.datevalue[1]){
          postbody.timerange = [this.datevalue[0].getTime(), this.datevalue[1].getTime()];
        }else{
          this.datevalue = (() => {
            var now = new Date(); // 当前日期
            var nowYear = now.getFullYear(); //当前年
            var nowMonth = now.getMonth(); //当前月 （值为0~11）注意此处范围
            var firstDay = new Date(nowYear, nowMonth, 1); // 本月开始时间
            var LastDay = new Date(nowYear, nowMonth + 1, 0); // 本月结束时间
            return [firstDay, LastDay];
          })();
          postbody.timerange = [this.datevalue[0].getTime(), this.datevalue[1].getTime()];
        }
        const { result } = await api.post('/admingetrank', postbody);
        for(var rankindex in result){
          result[rankindex].score = (parseFloat(result[rankindex].score) / 1000).toFixed(2);
        }
        console.log(result);
        this.tableData = result;
        this.allamount = result.length;
        this.loading = false;
      }catch(e){
        console.error(e);
        this.loading = false;
      }
    },
    async pushRank(){
      try{
        this.loading = true;
        const result = await api.post('/adminpushrank', {});
        console.log(result);
        this.$message.success('发布排名成功');
        this.loading = false;
        await this.getList();
      }catch(e){
        console.error(e);
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped>
.pagepadding {
  padding: 20px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}
.overhide{
  width: 144px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
